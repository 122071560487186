import React from "react";
import { styled } from "@mui/system";
import { Container, Typography } from "@mui/material";
const StarsContainer = styled("div")({
  width: "100%",
  height: "100%",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: -1,
  background: "radial-gradient(ellipse at bottom,   #141923, #090a0f 100%)",
});

const Title = styled("div")({
  position: "absolute",
  top: "50%",
  left: 0,
  right: 0,
  color: "#fff",
  textAlign: "center",
  fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
  fontWeight: 300,
  fontSize: "50px",
  letterSpacing: "10px",
  marginTop: "-60px",
  paddingLeft: "10px",
});

const GradientText = styled("span")({
  display: "inline-block", // Display as inline-block to keep all letters on the same line
  background: "-webkit-linear-gradient(white, #38495a)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const GradientTextOrange = styled("span")({
  display: "inline-block", // Display as inline-block to keep all letters on the same line
  background: "-webkit-linear-gradient(#D79E4F, #38495a)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const GradientTextBlue = styled("span")({
  display: "inline-block", // Display as inline-block to keep all letters on the same line
  background: "-webkit-linear-gradient(#4F88D7, #38495a)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});
const Footer = styled("footer")({
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: "10px 0",
    textAlign: "center",
    color: "#38495a",
  });
const Stars = styled("div")({
  width: "1px",
  height: "1px",
  background: "#fff", // Set the star color
  position: "absolute", // Use absolute positioning
  animation: "$animStar 50s linear infinite",
  "&:before, &:after": {
    content: '""',
    position: "absolute",
    width: "1px",
    height: "1px",
    background: "#fff", // Set the star color
  },
  "&:before": {
    animationName: "$animStarBefore",
  },
  "&:after": {
    animationName: "$animStarAfter",
  },
  "@keyframes animStarBefore": {
    to: {
      transform: "translateY(-2000px)",
    },
  },
  "@keyframes animStarAfter": {
    to: {
      transform: "translateY(-2000px)",
    },
  },
  "@keyframes animStar": {
    from: {
      transform: "translateY(0px)",
    },
    to: {
      transform: "translateY(-2000px)",
    },
  },
});

export function Home() {
    const currentYear = new Date().getFullYear();
  return (
    <Container>
      <StarsContainer>
        {[...Array(300)].map((_, index) => (
          <Stars key={index}></Stars>
        ))}
      </StarsContainer>
      <Title>
        <GradientText>Byte</GradientText>
        <GradientTextOrange>K</GradientTextOrange>
        <GradientTextBlue>ore</GradientTextBlue>
        <br /> {/* Add a line break */}
        
        <GradientText style={{fontSize: "22px", letterSpacing: "4px"}}>Something is coming.</GradientText>
      </Title>
      <Footer>
        <Typography variant="body2" color="inherit">
          &copy; {currentYear} ByteKore. All rights reserved.
        </Typography>
      </Footer>
    </Container>
  );
}
