import { Routes, Route } from "react-router-dom";
import Header from "./Components/header/Header";
import { Home } from "./Containers/Home/NewHome";
import { Products } from "./Containers/Products/Products";
import { ISVRequest } from "./Containers/ISV-Form/ISVRequest";

import { NotFound } from "./Components/NotFound";
function App() {
  const admin = true
  return (
    <>
      <Home admin={admin} />
      {/* <Header admin={admin} />
      <Routes>
        <Route path='/' element={<Home admin={admin} />} />
        <Route path='/products' element={<Products admin={admin} />} />
        <Route path='/components' element={<ISVRequest admin={admin} />} />
        <Route path="*" element={<NotFound admin={admin} />} />
      </Routes> */}
    </>
  )
}
export default App