import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
    return {
        mainPage:{
            backgroundColor: "#F2F1F0"
        },
        headerIcon:{
            display:"flex",
            alignItems: "center",
            textDecoration: "none",
            color: "#52688F",
            font: 'normal normal 600 38px/48px Tilt Warp',
            letterSpacing: "0px",
            
        },
        headerButton: {
            textDecoration: "none",
            color: "#171710",
            fontFamily: "Open Sans, sans-serif",
            font: 'normal normal 600 20px/48px Open Sans',
            letterSpacing: "0px",
            pointerEvents: "none",
            '.Mui-focusVisible': {

            }
        },
        headerMobileButton: {
            textDecoration: "none",
            color: "#171710",
            fontFamily: "Open Sans, sans-serif",
            font: 'normal normal 600 20px/20px Open Sans',
            letterSpacing: "0px",
            
        },
    }
    
})